import React from "react"
import RacePageOneDay2022 from "../components/racepageOneDay2022"

const Race2022 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Amstel Gold Race Ladies Edition",
      edition: 2022,
      what: "Race data",
      updated: "2022-04-09 17:34:05",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21872: {
        teamId: "21872",
        teamUciCode: "FDJ",
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        teamNationCode: "FRA",
      },
      21877: {
        teamId: "21877",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "NED",
      },
      21881: {
        teamId: "21881",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      22011: {
        teamId: "22011",
        teamUciCode: "SDW",
        teamName: "Team SD Worx",
        teamNationCode: "NED",
      },
      22296: {
        teamId: "22296",
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      22809: {
        teamId: "22809",
        teamUciCode: "BEX",
        teamName: "Team BikeExchange-Jayco",
        teamNationCode: "AUS",
      },
      23744: {
        teamId: "23744",
        teamUciCode: "CSR",
        teamName: "CANYON//SRAM Racing",
        teamNationCode: "GER",
      },
      23811: {
        teamId: "23811",
        teamUciCode: "WNT",
        teamName: "Ceratizit-WNT Pro Cycling",
        teamNationCode: "GER",
      },
      24281: {
        teamId: "24281",
        teamUciCode: "AGI",
        teamName: "AG Insurance-NXTG Team",
        teamNationCode: "NED",
      },
      24283: {
        teamId: "24283",
        teamUciCode: "PHV",
        teamName: "Parkhotel Valkenburg",
        teamNationCode: "NED",
      },
      27553: {
        teamId: "27553",
        teamUciCode: "UXT",
        teamName: "Uno-X Pro Cycling Team",
        teamNationCode: "NOR",
      },
      27799: {
        teamId: "27799",
        teamUciCode: "HPU",
        teamName: "Team Coop-Hitec Products",
        teamNationCode: "NOR",
      },
      27874: {
        teamId: "27874",
        teamUciCode: "LIV",
        teamName: "Liv Racing Xstra",
        teamNationCode: "NED",
      },
      27895: {
        teamId: "27895",
        teamUciCode: "JVW",
        teamName: "Team Jumbo-Visma",
        teamNationCode: "NED",
      },
      27926: {
        teamId: "27926",
        teamUciCode: "TIB",
        teamName: "EF Education-TIBCO-SVB",
        teamNationCode: "USA",
      },
      27951: {
        teamId: "27951",
        teamUciCode: "LEW",
        teamName: "Le Col Wahoo",
        teamNationCode: "GBR",
      },
      28260: {
        teamId: "28260",
        teamUciCode: "HPW",
        teamName: "Human Powered Health",
        teamNationCode: "USA",
      },
      28364: {
        teamId: "28364",
        teamUciCode: "ASC",
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        teamNationCode: "LUX",
      },
      28433: {
        teamId: "28433",
        teamUciCode: "CGS",
        teamName: "Roland Cogeas Edelweiss Squad",
        teamNationCode: "SUI",
      },
      28535: {
        teamId: "28535",
        teamUciCode: "GKT",
        teamName: "GT Krush Tunap Pro Cycling",
        teamNationCode: "NED",
      },
      28537: {
        teamId: "28537",
        teamUciCode: "PLP",
        teamName: "Plantur-Pura",
        teamNationCode: "BEL",
      },
      28572: {
        teamId: "28572",
        teamUciCode: "BCV",
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        teamNationCode: "BEL",
      },
      28954: {
        teamId: "28954",
        teamUciCode: "VAL",
        teamName: "Valcar-Travel & Service",
        teamNationCode: "ITA",
      },
      28991: {
        teamId: "28991",
        teamUciCode: "UAD",
        teamName: "UAE Team ADQ",
        teamNationCode: "UAE",
      },
    },
    riders: {
      57567: {
        id: 57567,
        startno: 116,
        firstName: "Eri",
        lastName: "Yonamine",
        nationCode: "JPN",
        nationName: "Japan",
        birthDate: "1991-04-25",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      96753: {
        id: 96753,
        startno: 82,
        firstName: "Silke",
        lastName: "Smulders",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-04-01",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      96746: {
        id: 96746,
        startno: 202,
        firstName: "Julia",
        lastName: "Borgstr\u00c3\u00b6m",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "2001-06-09",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
      },
      96720: {
        id: 96720,
        startno: 96,
        firstName: "Jade",
        lastName: "Wiel",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2000-04-02",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      89641: {
        id: 89641,
        startno: 151,
        firstName: "Elizabeth",
        lastName: "Holden",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1997-09-12",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      93562: {
        id: 93562,
        startno: 11,
        firstName: "Demi",
        lastName: "Vollering",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-11-15",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      93494: {
        id: 93494,
        startno: 185,
        firstName: "Claudia",
        lastName: "Jongerius",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-12-02",
        teamId: 28572,
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        stillInTheRace: "Y",
      },
      89843: {
        id: 89843,
        startno: 51,
        firstName: "Lauren",
        lastName: "Stephens",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1986-12-27",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      92988: {
        id: 92988,
        startno: 204,
        firstName: "Lone",
        lastName: "Meertens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-04-16",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
      },
      95830: {
        id: 95830,
        startno: 226,
        firstName: "Carolin",
        lastName: "Schiff",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1986-01-03",
        teamId: 28364,
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        stillInTheRace: "Y",
      },
      90014: {
        id: 90014,
        startno: 111,
        firstName: "Nina",
        lastName: "Buijsman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-11-16",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      89819: {
        id: 89819,
        startno: 34,
        firstName: "Mikayla",
        lastName: "Harvey",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1998-09-07",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      97208: {
        id: 97208,
        startno: 172,
        firstName: "Olivia",
        lastName: "Baril",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1997-10-10",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      89489: {
        id: 89489,
        startno: 75,
        firstName: "Erica",
        lastName: "Magnaldi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-08-24",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      96741: {
        id: 96741,
        startno: 163,
        firstName: "Femke",
        lastName: "Gerritse",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-05-14",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      89555: {
        id: 89555,
        startno: 16,
        firstName: "Ashleigh",
        lastName: "Moolman-Pasio",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1985-12-09",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      125411: {
        id: 125411,
        startno: 121,
        firstName: "Ines",
        lastName: "Cantera Carrasco",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2002-07-30",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      89471: {
        id: 89471,
        startno: 135,
        firstName: "Arianna",
        lastName: "Fidanza",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-01-06",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      90022: {
        id: 90022,
        startno: 235,
        firstName: "Nicole",
        lastName: "Steigenga",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-01-27",
        teamId: 27799,
        teamName: "Team Coop-Hitec Products",
        stillInTheRace: "Y",
      },
      91166: {
        id: 91166,
        startno: 221,
        firstName: "Michelle",
        lastName: "Andres",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1997-05-26",
        teamId: 28364,
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        stillInTheRace: "Y",
      },
      96734: {
        id: 96734,
        startno: 95,
        firstName: "Marie",
        lastName: "Le Net",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2000-01-25",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      89420: {
        id: 89420,
        startno: 1,
        firstName: "Riejanne",
        lastName: "Markus",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1994-09-01",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      104996: {
        id: 104996,
        startno: 145,
        firstName: "Anneke",
        lastName: "Dijkstra",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-05-21",
        teamId: 28535,
        teamName: "GT Krush Tunap Pro Cycling",
        stillInTheRace: "Y",
      },
      89828: {
        id: 89828,
        startno: 4,
        firstName: "Coryn",
        lastName: "Labecki",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1992-08-26",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      89433: {
        id: 89433,
        startno: 131,
        firstName: "Amanda",
        lastName: "Spratt",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1987-09-17",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      99971: {
        id: 99971,
        startno: 76,
        firstName: "Sophie",
        lastName: "Wright",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-03-15",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      99090: {
        id: 99090,
        startno: 155,
        firstName: "April",
        lastName: "Tacey",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2000-10-12",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      89832: {
        id: 89832,
        startno: 26,
        firstName: "Ellen",
        lastName: "Van Dijk",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-02-11",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      89954: {
        id: 89954,
        startno: 232,
        firstName: "Ingvild",
        lastName: "G\u00c3\u00a5skjenn",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1998-07-01",
        teamId: 27799,
        teamName: "Team Coop-Hitec Products",
        stillInTheRace: "Y",
      },
      114918: {
        id: 114918,
        startno: 86,
        firstName: "Ayesha",
        lastName: "McGowan",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1987-04-02",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      96756: {
        id: 96756,
        startno: 206,
        firstName: "Amber",
        lastName: "Aernouts",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2000-10-25",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
      },
      89424: {
        id: 89424,
        startno: 123,
        firstName: "Rotem",
        lastName: "Gafinovitz",
        nationCode: "ISR",
        nationName: "Israel",
        birthDate: "1992-06-09",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      154490: {
        id: 154490,
        startno: 144,
        firstName: "Carola",
        lastName: "Van De Wetering",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2003-04-22",
        teamId: 28535,
        teamName: "GT Krush Tunap Pro Cycling",
        stillInTheRace: "Y",
      },
      96032: {
        id: 96032,
        startno: 176,
        firstName: "Elizabeth",
        lastName: "Stannard",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1997-05-27",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      91260: {
        id: 91260,
        startno: 44,
        firstName: "Paula",
        lastName: "Patino",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-03-29",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      89983: {
        id: 89983,
        startno: 171,
        firstName: "Alice Maria",
        lastName: "Arzuffi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-11-19",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      114498: {
        id: 114498,
        startno: 205,
        firstName: "Ilse",
        lastName: "Pluimers",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2002-04-29",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
      },
      92095: {
        id: 92095,
        startno: 32,
        firstName: "Elise",
        lastName: "Chabbey",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-04-24",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      148614: {
        id: 148614,
        startno: 101,
        firstName: "Anniina",
        lastName: "Ahtosalo",
        nationCode: "FIN",
        nationName: "Finland",
        birthDate: "2003-08-27",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      96864: {
        id: 96864,
        startno: 174,
        firstName: "Federica",
        lastName: "Piergiovanni",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-07-30",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      90013: {
        id: 90013,
        startno: 81,
        firstName: "Eva",
        lastName: "Buurman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1994-09-07",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      89527: {
        id: 89527,
        startno: 195,
        firstName: "Hanna",
        lastName: "Nilsson",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "1992-02-16",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      89917: {
        id: 89917,
        startno: 42,
        firstName: "Aude",
        lastName: "Biannic",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-03-27",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      90870: {
        id: 90870,
        startno: 141,
        firstName: "Henrietta",
        lastName: "Colborne",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1998-04-20",
        teamId: 28535,
        teamName: "GT Krush Tunap Pro Cycling",
        stillInTheRace: "Y",
      },
      89405: {
        id: 89405,
        startno: 13,
        firstName: "Chantal",
        lastName: "van den Broek-Blaak",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1989-10-22",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      99107: {
        id: 99107,
        startno: 166,
        firstName: "Anne",
        lastName: "Van Rooijen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2000-06-04",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      177299: {
        id: 177299,
        startno: 53,
        firstName: "Veronica",
        lastName: "Ewers",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1994-09-01",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      96804: {
        id: 96804,
        startno: 184,
        firstName: "Lotte",
        lastName: "Popelier",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2001-05-21",
        teamId: 28572,
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        stillInTheRace: "Y",
      },
      96725: {
        id: 96725,
        startno: 104,
        firstName: "Hannah",
        lastName: "Ludwig",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2000-02-15",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      100016: {
        id: 100016,
        startno: 84,
        firstName: "Quinty",
        lastName: "Ton",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-08-04",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      92102: {
        id: 92102,
        startno: 14,
        firstName: "Niamh",
        lastName: "Fisher-Black",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "2000-08-12",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      89824: {
        id: 89824,
        startno: 63,
        firstName: "Leah",
        lastName: "Kirchmann",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1990-06-30",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      89470: {
        id: 89470,
        startno: 71,
        firstName: "Sofia",
        lastName: "Bertizzolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-08-21",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      96726: {
        id: 96726,
        startno: 162,
        firstName: "Mischa",
        lastName: "Bredewold",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2000-06-20",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      92703: {
        id: 92703,
        startno: 191,
        firstName: "Marta",
        lastName: "Lach",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1997-05-26",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      90596: {
        id: 90596,
        startno: 73,
        firstName: "Mavi",
        lastName: "Garcia",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1984-01-02",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      96873: {
        id: 96873,
        startno: 203,
        firstName: "Gaia",
        lastName: "Masetti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-10-26",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
      },
      157062: {
        id: 157062,
        startno: 224,
        firstName: "Lisa",
        lastName: "M\u00c3\u00bcllenberg",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-08-21",
        teamId: 28364,
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        stillInTheRace: "Y",
      },
      91167: {
        id: 91167,
        startno: 124,
        firstName: "Aline",
        lastName: "Seitz",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1997-02-17",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      89925: {
        id: 89925,
        startno: 94,
        firstName: "Victorie",
        lastName: "Guilman",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-03-25",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      89421: {
        id: 89421,
        startno: 2,
        firstName: "Anouska",
        lastName: "Koster",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-08-20",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      114475: {
        id: 114475,
        startno: 142,
        firstName: "Daniek",
        lastName: "Hengeveld",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2002-11-17",
        teamId: 28535,
        teamName: "GT Krush Tunap Pro Cycling",
        stillInTheRace: "Y",
      },
      98961: {
        id: 98961,
        startno: 183,
        firstName: "Olha",
        lastName: "Kulynych",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "2000-02-01",
        teamId: 28572,
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        stillInTheRace: "Y",
      },
      89430: {
        id: 89430,
        startno: 132,
        firstName: "Alexandra",
        lastName: "Manly",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1996-02-28",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      96817: {
        id: 96817,
        startno: 62,
        firstName: "Pfeiffer",
        lastName: "Georgi",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2000-09-27",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      167131: {
        id: 167131,
        startno: 234,
        firstName: "Josie",
        lastName: "Nelson",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2002-04-08",
        teamId: 27799,
        teamName: "Team Coop-Hitec Products",
        stillInTheRace: "Y",
      },
      114476: {
        id: 114476,
        startno: 25,
        firstName: "Shirin",
        lastName: "van Anrooij",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2002-02-05",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      114768: {
        id: 114768,
        startno: 105,
        firstName: "Anne Dorthe",
        lastName: "Ysland",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "2002-04-09",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      89711: {
        id: 89711,
        startno: 23,
        firstName: "Tayler",
        lastName: "Wiles",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1989-07-19",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      89886: {
        id: 89886,
        startno: 215,
        firstName: "Julie",
        lastName: "Van De Velde",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-06-02",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      107834: {
        id: 107834,
        startno: 15,
        firstName: "Blanka",
        lastName: "Vas",
        nationCode: "HUN",
        nationName: "Hungary",
        birthDate: "2001-09-03",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      127994: {
        id: 127994,
        startno: 143,
        firstName: "Femke",
        lastName: "De Vries",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1994-04-16",
        teamId: 28535,
        teamName: "GT Krush Tunap Pro Cycling",
        stillInTheRace: "Y",
      },
      89435: {
        id: 89435,
        startno: 136,
        firstName: "Georgia",
        lastName: "Williams",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1993-08-25",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      90063: {
        id: 90063,
        startno: 56,
        firstName: "Sara",
        lastName: "Poidevin",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1996-05-07",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      103237: {
        id: 103237,
        startno: 114,
        firstName: "Barbara",
        lastName: "Malcotti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-02-19",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      154491: {
        id: 154491,
        startno: 201,
        firstName: "Maureen",
        lastName: "Arens",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2003-04-16",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
      },
      98823: {
        id: 98823,
        startno: 133,
        firstName: "Chelsie Wei Shi",
        lastName: "Tan",
        nationCode: "SIN",
        nationName: "Singapore",
        birthDate: "1990-01-17",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      89870: {
        id: 89870,
        startno: 152,
        firstName: "Eider",
        lastName: "Merino",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-08-02",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      97831: {
        id: 97831,
        startno: 173,
        firstName: "Anastasia",
        lastName: "Carbonari",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-09-11",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      90019: {
        id: 90019,
        startno: 35,
        firstName: "Pauliena",
        lastName: "Rooijakkers",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-05-12",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      89739: {
        id: 89739,
        startno: 93,
        firstName: "Emilia",
        lastName: "Fahlin",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "1988-10-24",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      92687: {
        id: 92687,
        startno: 194,
        firstName: "Laura",
        lastName: "Asencio",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-05-14",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      90025: {
        id: 90025,
        startno: 6,
        firstName: "Karlijn",
        lastName: "Swinkels",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-10-28",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      96698: {
        id: 96698,
        startno: 165,
        firstName: "Lieke",
        lastName: "Nooijen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-07-20",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      89596: {
        id: 89596,
        startno: 52,
        firstName: "Krista",
        lastName: "Doebel-Hickok",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1989-04-28",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      125562: {
        id: 125562,
        startno: 182,
        firstName: "Naomi",
        lastName: "De Roeck",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-10-23",
        teamId: 28572,
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        stillInTheRace: "Y",
      },
      90984: {
        id: 90984,
        startno: 103,
        firstName: "Joscelin",
        lastName: "Lowden",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1987-10-03",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      89826: {
        id: 89826,
        startno: 65,
        firstName: "Liane",
        lastName: "Lippert",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1998-01-13",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      89827: {
        id: 89827,
        startno: 61,
        firstName: "Floortje",
        lastName: "Mackaij",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-10-18",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      152964: {
        id: 152964,
        startno: 233,
        firstName: "Ane",
        lastName: "Iversen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1992-12-11",
        teamId: 27799,
        teamName: "Team Coop-Hitec Products",
        stillInTheRace: "Y",
      },
      99155: {
        id: 99155,
        startno: 212,
        firstName: "Kiona",
        lastName: "Crabbe",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2001-02-18",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      89448: {
        id: 89448,
        startno: 36,
        firstName: "Soraya",
        lastName: "Paladin",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-04",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      96709: {
        id: 96709,
        startno: 154,
        firstName: "Maike",
        lastName: "van der Duin",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-09-12",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      89419: {
        id: 89419,
        startno: 31,
        firstName: "Katarzyna",
        lastName: "Niewiadoma",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1994-09-29",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      92093: {
        id: 92093,
        startno: 43,
        firstName: "Emma",
        lastName: "Norsgaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1999-07-26",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      89484: {
        id: 89484,
        startno: 196,
        firstName: "Lara",
        lastName: "Vieceli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-07-16",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      93682: {
        id: 93682,
        startno: 216,
        firstName: "Inge",
        lastName: "Van der Heijden",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-08-12",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      92485: {
        id: 92485,
        startno: 12,
        firstName: "Marlen",
        lastName: "Reusser",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1991-09-20",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      89825: {
        id: 89825,
        startno: 64,
        firstName: "Juliette",
        lastName: "Labous",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-11-04",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      90976: {
        id: 90976,
        startno: 192,
        firstName: "Lizbeth Yareli",
        lastName: "Salazar Vazquez",
        nationCode: "MEX",
        nationName: "Mexico",
        birthDate: "1996-12-08",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      89522: {
        id: 89522,
        startno: 74,
        firstName: "Maaike",
        lastName: "Boogaard",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-08-24",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      96793: {
        id: 96793,
        startno: 231,
        firstName: "Caroline",
        lastName: "Andersson",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "2001-07-29",
        teamId: 27799,
        teamName: "Team Coop-Hitec Products",
        stillInTheRace: "Y",
      },
      89538: {
        id: 89538,
        startno: 33,
        firstName: "Tiffany",
        lastName: "Cromwell",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1988-07-06",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      93655: {
        id: 93655,
        startno: 3,
        firstName: "Anna",
        lastName: "Henderson",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1998-11-14",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      154301: {
        id: 154301,
        startno: 153,
        firstName: "Alice",
        lastName: "Towers",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2002-10-12",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      89823: {
        id: 89823,
        startno: 22,
        firstName: "Lucinda",
        lastName: "Brand",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1989-07-02",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      89950: {
        id: 89950,
        startno: 46,
        firstName: "Katrine",
        lastName: "Aalerud",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1994-12-04",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      96724: {
        id: 96724,
        startno: 113,
        firstName: "Katie",
        lastName: "Clouse",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2001-08-04",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      164720: {
        id: 164720,
        startno: 213,
        firstName: "Justine",
        lastName: "Ghekiere",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-05-14",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      89839: {
        id: 89839,
        startno: 54,
        firstName: "Kathrin",
        lastName: "Hammes",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1989-01-09",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      90117: {
        id: 90117,
        startno: 115,
        firstName: "Marit",
        lastName: "Raaijmakers",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-06-02",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      113731: {
        id: 113731,
        startno: 112,
        firstName: "Henrietta",
        lastName: "Christie",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "2002-01-23",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      106424: {
        id: 106424,
        startno: 55,
        firstName: "Magdeleine",
        lastName: "Vallieres Mill",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "2001-08-10",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      89716: {
        id: 89716,
        startno: 91,
        firstName: "Marta",
        lastName: "Cavalli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-03-18",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      96719: {
        id: 96719,
        startno: 161,
        firstName: "Pien",
        lastName: "Limpens",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-02-24",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      138754: {
        id: 138754,
        startno: 122,
        firstName: "Tamara",
        lastName: "Dronova",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1993-08-13",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      89450: {
        id: 89450,
        startno: 134,
        firstName: "Ane",
        lastName: "Santesteban",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-12-12",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      89454: {
        id: 89454,
        startno: 72,
        firstName: "Anna",
        lastName: "Trevisi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-05-08",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      96846: {
        id: 96846,
        startno: 236,
        firstName: "Sylvie",
        lastName: "Swinkels",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2000-07-31",
        teamId: 27799,
        teamName: "Team Coop-Hitec Products",
        stillInTheRace: "Y",
      },
      91165: {
        id: 91165,
        startno: 225,
        firstName: "Lena",
        lastName: "Mettraux",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-09-08",
        teamId: 28364,
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        stillInTheRace: "Y",
      },
      100561: {
        id: 100561,
        startno: 156,
        firstName: "Gladys",
        lastName: "Verhulst",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-01-02",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      97542: {
        id: 97542,
        startno: 5,
        firstName: "Teuntje",
        lastName: "Beekhuis",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-08-18",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      94111: {
        id: 94111,
        startno: 164,
        firstName: "Kirstie",
        lastName: "Van Haaften",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-01-21",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      89801: {
        id: 89801,
        startno: 24,
        firstName: "Leah",
        lastName: "Thomas",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1989-05-29",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      176998: {
        id: 176998,
        startno: 181,
        firstName: "Lotte",
        lastName: "Claes",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-05-05",
        teamId: 28572,
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        stillInTheRace: "Y",
      },
      89535: {
        id: 89535,
        startno: 102,
        firstName: "Hannah",
        lastName: "Barnes",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1993-05-04",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      176810: {
        id: 176810,
        startno: 214,
        firstName: "Millie",
        lastName: "Couzens",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2003-10-29",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      89434: {
        id: 89434,
        startno: 41,
        firstName: "Annemiek",
        lastName: "van Vleuten",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1982-10-08",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      92142: {
        id: 92142,
        startno: 92,
        firstName: "Stine",
        lastName: "Borgli",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1990-07-04",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      89503: {
        id: 89503,
        startno: 126,
        firstName: "Olga",
        lastName: "Zabelinskaya",
        nationCode: "UZB",
        nationName: "Uzbekistan",
        birthDate: "1980-05-10",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      89807: {
        id: 89807,
        startno: 83,
        firstName: "Valerie",
        lastName: "Demey",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-01-17",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      89714: {
        id: 89714,
        startno: 21,
        firstName: "Elisa",
        lastName: "Balsamo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-02-27",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      114801: {
        id: 114801,
        startno: 146,
        firstName: "Petra",
        lastName: "Welmers",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2002-03-25",
        teamId: 28535,
        teamName: "GT Krush Tunap Pro Cycling",
        stillInTheRace: "Y",
      },
      112048: {
        id: 112048,
        startno: 222,
        firstName: "Georgia",
        lastName: "Danford",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1999-04-03",
        teamId: 28364,
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        stillInTheRace: "Y",
      },
      89480: {
        id: 89480,
        startno: 45,
        firstName: "Arlenis",
        lastName: "Sierra",
        nationCode: "CUB",
        nationName: "Cuba",
        birthDate: "1992-12-07",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      90865: {
        id: 90865,
        startno: 175,
        firstName: "Elena",
        lastName: "Pirrone",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-02-21",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      89423: {
        id: 89423,
        startno: 211,
        firstName: "Yara",
        lastName: "Kastelijn",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-08-09",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      159727: {
        id: 159727,
        startno: 125,
        firstName: "Petra",
        lastName: "Stiasny",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "2001-09-10",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      96716: {
        id: 96716,
        startno: 66,
        firstName: "L\u00c3\u00a9a",
        lastName: "Curinier",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2001-04-21",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "Amstel Gold Race"
  const raceID = 9050

  return (
    <RacePageOneDay2022
      title={`${race} 2022`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2022
